





























































import { Component, Vue } from 'vue-property-decorator';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers, dispatchDeleteUser } from '@/store/admin/actions';
import {readAppSettings} from "@/store/main/getters";

@Component
export default class AdminUsers extends Vue {

  public searchTerm: string = "";
  public showInactive: boolean = false;
  public canAddUsers: boolean = true;

  get headers() {
    return [
    {
      text: 'id',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'User ID',
      sortable: true,
      value: 'user_id',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Display Name',
      sortable: true,
      value: 'display_name',
      align: 'left',
    },
    {
      text: 'Is Active',
      value: 'is_active',
      align: 'left',
      filter: value => this.showInactive || value
    },
    {
      text: 'Role',
      sortable: true,
      filterable: false,
      value: 'role.name',
      align: 'left',
    },
    {
      sortable: false,
      filterable: false,
      text: 'Actions',
      value: 'action'
    }
    ]
  }

  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    const appSettings = await readAppSettings(this.$store);
    this.canAddUsers = !appSettings || !appSettings.seats_available || appSettings.seats_available > 0;
  }

  public async deleteUser(user) {
    if (await this.$dialog.confirm({
      text: `Are you sure you want to delete user <strong>${user.user_id}</strong>?`,
      title: 'Delete user'
    })) {
      try {
        await dispatchDeleteUser(this.$store, {id: user.id});
      } catch (e) {
        console.error(e.message);
      }
    }
  }
}
