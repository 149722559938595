













































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { commitAddNotification, commitRemoveNotification } from '@/store/main/mutations';
import { readToken } from '@/store/main/getters';

@Component
export default class AdminUsers extends Vue {

  public emailToInvite: string = "";
  public link: string = "";

  public async sendEmail() {
    if (this.emailToInvite) {
      const loadingNotification = { content: 'Sending...', showProgress: true };
      try {
          commitAddNotification(this.$store, loadingNotification);
          const response = await api.inviteToSignup(readToken(this.$store), this.emailToInvite);
          commitRemoveNotification(this.$store, loadingNotification);
          commitAddNotification(this.$store, { content: response.data.msg, color: 'success'});
          this.emailToInvite = "";
      } catch (error) {
          commitRemoveNotification(this.$store, loadingNotification);
          commitAddNotification(this.$store, { content: error.response.data.detail, color: 'error' });
      }
    }
  }
  
  public async generateLink() {
    const loadingNotification = { content: 'Generating...', showProgress: true };
    try {
        commitAddNotification(this.$store, loadingNotification);
        const response = await api.getSignupLink(readToken(this.$store));
        commitRemoveNotification(this.$store, loadingNotification);
        this.link = response.data.msg;
    } catch (error) {
        commitRemoveNotification(this.$store, loadingNotification);
        commitAddNotification(this.$store, { content: 'Could not reach server', color: 'error' });
    }
  }

  public copyLink() {
    (this.$refs["link"] as Vue).$el.querySelector('input')?.select();
    document.execCommand("copy");
    commitAddNotification(this.$store, {content: "Copied to clipboard", color: "success"});
  }

}
